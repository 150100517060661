@tailwind base;

@tailwind components;

@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Dongle:wght@300;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Bellota+Text:wght@300;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Dongle:wght@300;400;700&family=Fredoka:wght@300;400;500;600;700&family=Mina:wght@400;700&family=Miriam+Libre:wght@400;700&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Manjari:wght@100;400;700&display=swap');
.modalClass {
   @apply max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-lg shadow-md text-center

}

.modalOverlay {
   @apply bg-black z-50 bg-opacity-30 fixed inset-0 overflow-y-auto

}

.video-react .video-react-control-bar .video-react-play-control,
.video-react .video-react-control-bar .video-react-pause-control {
  display: none;
}




/* PRODUCT DETAILS CAROUSEL STYLING CUSTOMIZATION START */
.postproductCarousel.carousel-root {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   gap: 15px;
}
.postproductCarousel.carousel-root .carousel {
   width: auto;
}
.productCarousel.carousel-root .carousel img {
   width: 100%;
   height: 120px;
   object-fit: cover;
   object-position: center;
}

.postproductCarousel.carousel-root .carousel.carousel-slider {
   width: 100%;
}
.postproductCarousel.carousel-root .carousel.carousel-slider .slider-wrapper {
   border-radius: 10px;
}
.postproductCarousel.carousel-root .carousel .thumbs-wrapper {
   overflow: auto;
   margin: 0;
   
   
}
.postproductCarousel.carousel-root .carousel .thumb {
   height: 50px;
   border-radius: 6px;
   overflow: hidden;
   position: relative;
}
.postproductCarousel.carousel-root .carousel .thumb img {
   width: 100%;
   height: 100%;
   object-fit: cover;
   object-position: center;
}
.postproductCarousel.carousel-root .carousel .thumb:after {
   content: "";
   width: 100%;
   height: 100%;
   position: absolute;
   top: 0;
   left: 0;
   background-color: black;
   opacity: 0;
}
.postproductCarousel.carousel-root .carousel .thumb.selected,
.postproductCarousel.carousel-root .carousel .thumb:hover {
   border: 0;
}
.postproductCarousel.carousel-root .carousel .thumb:hover:after,
.postproductCarousel.carousel-root .carousel .thumb.selected:after {
   opacity: 0.2;
}
.postproductCarousel .control-arrow {
   display: none;
}
@media screen and (max-width: 767px) {
   .postproductCarousel.carousel-root {
       flex-direction: column;
       align-items: center;
   }
   .postproductCarousel.carousel-root .carousel .thumb {
       border: 0;
       padding: 0;
   }
}
@media screen and (min-width: 768px) {
   .postproductCarousel.carousel-root .carousel .thumbs {
       transform: none !important;
       flex-direction: column;
       display: flex;
       gap: 10px;
       height: 140px;
       overflow: auto;
       
       
   }
   .postproductCarousel.carousel-root .carousel .thumb {
       border: 0;
       padding: 0;
       margin: 0;
       
   }
}
/* PRODUCT DETAILS CAROUSEL STYLING CUSTOMIZATION END */
